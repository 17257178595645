import { post } from '../request'

//创建赛事新闻系统栏目
export function CreateSubLeagueSscmsChannel(params){
    return post('NewApi/LeagueOwner/CreateSubLeagueSscmsChannel',params)
  }
//获取子赛事新闻系统栏目配置
export function GetSubleagueSscmsChannelConfig(params){
    return post('NewApi/LeagueOwner/GetSubleagueSscmsChannelConfig',params)
}
//保存子赛事新闻系统栏目配置
export function SaveSubleagueSscmsChannelConfig(params){
    return post('NewApi/LeagueOwner/SaveSubleagueSscmsChannelConfig',params)
}